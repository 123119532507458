import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { WEBINAR_CONFERENCE_EVENTS } from 'src/app/conference/constants';
import { WEBINAR_CONFERENCE_SOCKET_EVENTS } from 'src/app/conference/constants/webinar-conference-socket-events';
import { WebinarConferenceService } from 'src/app/conference/services';
import { APP_EVENTS, INSIDE_EVENT } from 'src/app/constants';
import { EventDataSource } from 'src/app/constants/webinar-enum';
import { FloatingReactionService } from 'src/app/shared/services/floating-reaction.service';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import { CallStateManagerService } from '../classes/call-state-manager.service';
import { AppService } from './app.service';
import { EventEmitterService } from './event-emitter.service';
import { GoogleTagMangerService } from './google-tag-manger.service';
import { RoomConnectionService } from './room-connection.service';
import { RtcService } from './rtc.service';
import { SocketEvent } from './socket.service';
import { UserService } from './user.service';
import { UtilService } from './util.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { EventsPlusService } from './events-plus.service';

@Injectable({
    providedIn: 'root'
})
export class CallViewStateManagerService {
    viewController = {
        qa: false,
        participants: false,
        chat: false,
        leaderboard: false,
        private: false,
        comment: false,
        ticker: false,
        polls: false,
        schedule: false,
        new_message: false,
        popoutchat: false,
        games: false,
        liveChat: false
    };
    calloptionsPanelOpenState = false;
    webinarOptions;
    webinarId: any;
    public eventStats = {
        viewsCount: 0,
        likesCount: 0,
        likeOptions: []
    };
    meetingObj: any;
    meetingId: any; // of session?
    showLeaderboardAchievement: boolean = false;

    roomStatus;
    spotlightedParticipants: any;
    prevHandRaisedState: boolean;
    mediaShared: any;
    isVidyoMeeting: any;
    isSingleFeed: boolean;
    tickerObj: any;
    currentUser: any;
    callJoinTime;
    loading;
    pollingEventInterval;
    isHost: any;
    isOnlyHost;
    isEventCoHost: boolean = false;
    showShareEventSocialPopup: boolean = false;
    isSpeaker: boolean = false;
    ifPromoted$: Subject<any> = new Subject();

    callViewState$: Subject<any> = new Subject();
    currentActiveTabState$: Subject<any> = new Subject();

    callScreenState: 'pip' | 'compact' | 'normal' | 'full' = 'normal';
    callScreenDragPosition = { x: 0, y: 0 };

    midPosX = (window.innerWidth - 900) / 2;
    midPosY = (window.innerHeight - 604) / 2;
    pipPosX = window.innerWidth * 0.8 - (16 / 9) * 0.25 * 0.5 * window.innerHeight;
    pipPosY = window.innerHeight * 0.7 - 0.25 * 0.5 * window.innerHeight;

    notifications$: BehaviorSubject<any> = new BehaviorSubject(0);
    notificationCount = {
        chat: 0,
        qa: 0,
        comment: 0,
        liveChat: 0,
        games: 0
    };
    currentActiveTabId = 0;
    isUserInFeedbackScreen: any;
    isMobileBrowser: boolean = false;
    eventUpdatesObj: any;
    eventSpeakers: any = [];
    gtmMeetingInfo: any;
    isBroadcasting: boolean = false;
    eventStatusUpdates$: BehaviorSubject<any> = new BehaviorSubject({});
    layoutDetails$: BehaviorSubject<any> = new BehaviorSubject({});
    sessionId: any;
    constructor(
        public rtcService: RtcService,
        private utilService: UtilService,
        private webinarConfService: WebinarConferenceService,
        private floatingService: FloatingReactionService,
        private webinarAttendeeService: WebinarAttendeeService,
        private callStateManagerService: CallStateManagerService,
        private roomConnectionService: RoomConnectionService,
        private userService: UserService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private googleTagManager: GoogleTagMangerService,
        private toasterService: ToastrService,
        private eventsPlusService: EventsPlusService
    ) {}

    setCurrentUser() {
        this.currentUser = this.userService.getUserSync();
    }

    updateMeetingObject(val) {
        this.meetingObj = val;
        this.gtmMeetingInfo = {
            meeting_Id: this.meetingObj?.meetingInfo._id,
            Category: this.meetingObj?.meetingInfo?.jioConf?.eventType,
            expected_attendee_count: this.meetingObj?.meetingInfo?.jioConf?.expectedAttendeeCount,
            tags: this.meetingObj?.meetingInfo?.jioConf?.tags
        };
    }

    setCallScreenState(val) {
        this.hideCalloptions();
        this.callScreenState = val;
        if (val === 'pip') {
            this.setDragPosition(this.pipPosX, this.pipPosY);
        } else if (val === 'compact') {
            this.setDragPosition(this.midPosX, this.midPosY);
        } else {
            this.setDragPosition(0, 0);
        }
        // if (
        //     (this.callStateManagerService.isUserInCall === 'mainstage' ||
        //         this.callStateManagerService.isUserInCall === 'attendeestage') &&
        //     this.callScreenState !== 'pip' &&
        //     !this.callStateManagerService.networkingRoom
        // ) {
        //     this.callScreenDragPosition = { x: 0, y: 0 };
        // } else {
        //     this.callScreenDragPosition = { x: posX, y: posY };
        // }
        this.updateCallViewState();
    }

    disablePipMode() {
        this.setCallScreenState('normal');
        this.callStateManagerService.setCallFullScreenTab(false);
        if (this.callStateManagerService.isUserInCall === 'networking') {
            this.eventEmitterService.emit({ type: 'NAVIGATE_TO_NETWORKING_LOUNGE', data: null });
        } else if (
            this.callStateManagerService.isUserInCall === 'mainstage' ||
            this.callStateManagerService.isUserInCall === 'attendeestage'
        ) {
            this.eventEmitterService.emit({ type: 'NAVIGATE_TO_MAIN_STAGE', data: null });
        }
    }

    updateFeedbackState(bool) {
        this.isUserInFeedbackScreen = bool;
    }

    resizeCallScreen(increment) {
        // if(!this.callStateManagerService.networkingRoom) return;
        // if(increment && this.callScreenState === 'full') {
        //     this.callScreenState = 'compact';
        // } else if(increment && this.callScreenState === 'compact') {
        //     this.callScreenState = 'normal';
        // } else if(increment && this.callScreenState === 'pip') {
        //     this.callScreenState = 'compact';
        // } else {
        //     if(this.callScreenState = )
        // }
    }

    // goIntoPipMode() {
    //     this.setCallScreenState
    // }

    setDragPosition(posX, posY) {
        this.callScreenDragPosition = { x: posX, y: posY };
    }

    async getLatestEventUpdates() {
        if (this.isSpeaker) {
            await this.getEventUpdates();
        } else {
            await this.getEventUpdatesForAttendee();
        }
    }

    getEventUpdatesForAttendee() {
        this.webinarAttendeeService.getPollingEventData(this.sessionId ?? this.webinarId).subscribe((res) => {
            this.eventsPlusService.setCustomLayoutDetails(res?.customLayout);
        });
    }

    async getEventUpdates() {
        if (this.meetingObj?.meetingId) {
            return new Promise((resolve, reject) => {
                this.webinarConfService.getPollingEventData(this.meetingObj?.meetingId).subscribe(
                    (res) => {
                        res[WEBINAR_CONFERENCE_SOCKET_EVENTS.YT_LIVESTREAM_ERROR] = {
                            streaming: !res.ytlivestreamerror,
                            meetingId: res.meetingId
                        };
                        res[WEBINAR_CONFERENCE_SOCKET_EVENTS.FB_LIVESTREAM_ERROR] = {
                            streaming: !res.fblivestreamerror,
                            meetingId: res.meetingId
                        };
                        res[WEBINAR_CONFERENCE_SOCKET_EVENTS.CUSTOM_LIVESTREAM_ERROR] = {
                            streaming: !res.customlivestreamerror,
                            meetingId: res.meetingId
                        };
                        this.setEventUpdates(res);
                        resolve(res);
                    },
                    (err) => {
                        reject(err);
                    }
                );
            });
        }
    }

    getCloudVideoPayload() {
        // await this.getEventUpdates();
        return this.eventUpdatesObj?.cloudVideoMediaPayload;
    }

    async getLatestCustomLayoutFromRedis(forAttendee = false) {
        const updates: any = await this.getEventUpdates();
        return forAttendee ? updates.customLayout?.lastPublishedLayout : updates.customLayout?.lastSavedLayout;
    }

    setEventUpdates(obj) {
        if (!this.eventUpdatesObj || Object.keys(this.eventUpdatesObj)?.length === 0) {
            this.getLikeDetails();
        }
        if (!_.isEqual(this.eventUpdatesObj, obj)) {
            this.ifPromoted$.next(obj);
        }
        this.eventUpdatesObj = obj;
        this.eventStatusUpdates$.next(this.eventUpdatesObj);
        this.udpateBroadcastingState();
        this.updateCustomLayoutDetails(obj);
    }

    updateCustomLayoutDetails(obj) {
        this.eventsPlusService.setCustomLayoutDetails(obj?.customLayout);
        this.eventsPlusService.setBroadcastingState(obj.broadcastingState);
        this.eventsPlusService.scenePlayingNow = obj.customLayout?.lastPublishedLayout?.sceneNumber;
        this.layoutDetails$.next(obj.customLayout);
    }

    getCustomLayoutObj() {
        return this.layoutDetails$;
    }

    // roomstatus
    handleRoomConnectionStatusPolling() {
        this.roomConnectionService.getRoomConnectionStatus$().subscribe((roomStatus) => {
            if (!roomStatus || roomStatus?.success === false) {
                return;
            }
            this.roomStatus = roomStatus;
            this.isHost = roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost;
            this.isOnlyHost = roomStatus?.localParticipant?.isHost;
            // spotlighted participants are stored to show the spotlight list table.
            this.spotlightedParticipants = this.roomStatus.participants.filter((p) => {
                return this.roomStatus?.spotlightedParticipants.includes(p.participantId);
            });
            if (this.roomStatus?.localParticipant?.isHandRaise) {
                this.prevHandRaisedState = true;
            }
            // if (this.roomStatus?.localParticipant?.isHost || this.roomStatus?.localParticipant?.isCoHost) {
            //     this.getTicker();
            // }
        });
    }

    setEventSpeakers(speakers) {
        this.eventSpeakers = speakers || [];
        if (this.eventSpeakers.length === 0 || !this.currentUser || Object.keys(this.currentUser).length === 0) return;
        this.eventSpeakers.every((s) => {
            if (s.userId === this.currentUser?._id && s.role === 'Co-Host') {
                this.isEventCoHost = true;
                return false;
            }
            return true;
        });
    }

    getTicker() {
        if (this.meetingObj?.meetingId) {
            this.webinarConfService.getTicker(this.meetingObj?.meetingId).subscribe((res: any) => {
                this.formatTicker(res);
            });
        }
    }

    formatTicker(tickerData) {
        if (this.callStateManagerService.getCurrentMeetingId() !== tickerData.meetingId) {
            return;
        }
        this.tickerObj.enabled = tickerData.enabled;
        this.meetingObj.webinarOptions.ticker.enabled = tickerData?.enabled;
        this.tickerObj.tickerList = [];
        if (this.tickerObj.enabled) {
            tickerData.tickers.map((ticker) => {
                if (Object.keys(ticker).includes('components')) {
                    let text = '';
                    text = ticker.withUserName ? ticker.name + ' : ' : '';
                    text += ticker.components[0].content;
                    this.tickerObj.tickerList.push(text);
                } else {
                    this.tickerObj.tickerList.push(ticker.text);
                }
            });
            this.tickerObj.tickerList = this.tickerObj.tickerList.map((ticker) => {
                return this.utilService.htmlDecode(decodeURIComponent(ticker));
            });
        }
    }

    setCallScreenDragPosition(val) {
        this.callScreenDragPosition = val;
    }

    getBroadcastingState() {
        return (
            this.eventUpdatesObj.broadcastingState === 'webinarresumed' ||
            this.eventUpdatesObj.broadcastingState === 'webinarstarted'
        );
    }

    udpateBroadcastingState() {
        this.isBroadcasting =
            this.eventUpdatesObj.broadcastingState === 'webinarresumed' ||
            this.eventUpdatesObj.broadcastingState === 'webinarstarted';
    }

    toggleCallOptionsPanel(option = null, source = null) {
        if (source === 'browserNotification' && option) {
            if (this.callScreenState === 'pip') {
                this.eventEmitterService.emit({ type: APP_EVENTS.NAVIGATE_TO_MAIN_STAGE, data: null });
            }
            if (this.viewController[option]) {
                return;
            }

            this.setDefaultViewControllerState();
            this.viewController[option] = true;
            this.calloptionsPanelOpenState = true;
            this.updateCallViewState('callOptionsPanelOpen');
            return;
        }

        if (this.callStateManagerService.networkingRoom && option) {
            this.setCallScreenState('normal');
        }

        // Handle cases where no option is provided or the option is already active
        if (!option || this.viewController[option]) {
            this.updateCallViewState('beforeCallOptionsPanelClose');
            this.setDefaultViewControllerState();
            this.calloptionsPanelOpenState = false;
            this.updateCallViewState('callOptionsPanelClose');
            return;
        } else {
            this.updateCallViewState('beforeCallOptionsPanelOpen');
            this.setDefaultViewControllerState();
            this.viewController[option] = true;
            this.callControlsOption(option);
            this.calloptionsPanelOpenState = option !== 'popoutchat';
            this.updateCallViewState('callOptionsPanelOpen');
        }
    }

    callControlsOption(option) {
        switch (option) {
            case 'comment':
                this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.COMMENTS, this.gtmMeetingInfo);
                break;
            case 'qa':
                this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.Q_AND_A, this.gtmMeetingInfo);
                break;
            case 'polls':
                this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.POLLS, this.gtmMeetingInfo);
                break;
            case 'chat':
                this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.CHAT, this.gtmMeetingInfo);
                break;
            case 'participants':
                this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.PARTICIPANT_PANEL, this.gtmMeetingInfo);
                break;
            case 'ticker':
                this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.MANAGE_TICKER, this.gtmMeetingInfo);
                break;
        }
    }

    closeAllViewPops() {
        this.toggleCallOptionsPanel();
        this.updateCallViewState('cleanViewState');
    }

    updateCallViewState(e = 'callScreenState') {
        this.callViewState$.next(e);
    }

    getViewStateObs() {
        return this.callViewState$;
    }

    setDefaultViewControllerState() {
        this.viewController = {
            qa: false,
            participants: false,
            chat: false,
            leaderboard: false,
            private: false,
            comment: false,
            ticker: false,
            polls: false,
            schedule: false,
            new_message: false,
            popoutchat: false,
            liveChat: false,
            games: false
        };
    }

    hideCalloptions() {
        this.setDefaultViewControllerState();
        this.calloptionsPanelOpenState = false;
    }

    setCurrentActiveTab(id) {
        this.currentActiveTabId = id;
        this.currentActiveTabState$.next(id);
    }

    currentNavTabUpdates() {
        return this.currentActiveTabState$;
    }

    updateWebinarOptions() {
        // this is for attendee
        this.webinarAttendeeService.getWebinarOptionsForAttendee(this.webinarId).subscribe((res: any) => {
            this.webinarOptions = res.webinarOptions;
            this.setReactions(this.webinarOptions.likes.reactionType);
        });
    }

    setWebinarId(val) {
        // ParentEventId, ParentMeetingId, ParentWebinarId
        this.webinarId = val;
    }

    listenToParticipantStatus() {}

    updateEventViewCount(data) {
        // var webinarId =
        //     _.get(this.webinarDetails, 'sessionsEnabled') || this.sessionId ? this.sessionId : this.webinarId;
        if (this.meetingObj.meetingId === data.meetingId) {
            // || webinarId === data.meetingId
            this.eventStats.viewsCount = this.utilService.convertIntegerToShortFormat(data.viewsCount);
            // this.eventEmitterService.emit({
            //     type: WEBINAR_CONFERENCE_EVENTS.VIEW_COUNT,
            //     data: this.eventStats.viewsCount
            // });
        }
    }

    updateEventReactionsCount(data) {
        // var webinarId =
        //     _.get(this.webinarDetails, 'sessionsEnabled') || this.sessionId ? this.sessionId : this.webinarId;
        if (this.meetingObj.meetingId === data.meetingId) {
            // || webinarId === data.meetingId
            // this.eventStats.likesCount = this.utilService.convertIntegerToShortFormat(data.likesCount);
            this.getLikeDetails();
            // this.eventEmitterService.emit({
            //     type: WEBINAR_CONFERENCE_EVENTS.VIEW_COUNT,
            //     data: this.eventStats.viewsCount
            // });
        }
    }

    sendNotificationCountUpdate() {
        this.notifications$.next(this.notificationCount);
    }

    updateNotificationCount(key, count) {
        if (this.notificationCount[key] === count) return;
        this.notificationCount[key] = count;
        this.sendNotificationCountUpdate();
    }

    setChatNotifyCount(data) {
        if (
            data.type === 'message' &&
            data.createdBy !== this.callStateManagerService.userID() &&
            data?.jiomeetId === this.meetingObj.jiomeetId &&
            !this.viewController['chat']
        ) {
            this.updateNotificationCount('chat', this.notificationCount['chat'] + 1);
            // if (
            //     data?.recipients?.length === 2 &&
            //     data?.recipients?.find((user) => user.userId === event.data.createdBy) &&
            //     data?.recipients?.find((user) => user.userId === this.userID()) &&
            //     (data.context.startsWith('u-') || data.context.startsWith('gu-'))
            // ) {
            //     const toast = this.toastrService.info(
            //         `You have a new message from ${data.creatorsName} ${data.creatorsLName || ''} `.trim(),
            //         '',
            //         {
            //             positionClass: 'toast-center-center',
            //             toastClass: 'inside-call-chat-toast pointer'
            //         }
            //     );
            //     this.subscriptions.push(
            //         toast.onTap.subscribe(() => {
            //             this.toggleChat();
            //         }),
            //         toast.onShown.subscribe(() => {
            //             this.privateChatOnlyHandler = {
            //                 _id: event.data.createdBy,
            //                 name: `${data.creatorsName} ${data.creatorsLName || ''}`.trim()
            //             };
            //         })
            //     );
            // }
        }
    }

    setCommentNotifyCount(event) {
        if (event?.data?.refId != this.meetingObj?.meetingId) return;
        if (this.viewController['comment']) return;
        this.updateNotificationCount('comment', this.notificationCount['comment'] + 1);
    }

    setReactions(arr?) {
        this.eventStats.likeOptions = [];
        if (!this.webinarOptions) {
            this.webinarOptions = this.callStateManagerService.getCurrentMeetingObject()?.webinarOptions;
        }
        this.webinarOptions?.likes?.reactionType?.forEach((reaction) => {
            this.eventStats?.likeOptions.push({ type: reaction, count: 0 });
        });
    }

    getLikeDetails(event = null) {
        // if (!this.isLikesViewsTooltipSet) {
        //     this.isLikesViewsTooltipSet = true;
        //     this.setLikesViewsTickerText();
        // }
        // if (
        //     event &&
        //     !(
        //         this.meetingObj?.webinarOptions?.likes?.enabled &&
        //         (this.meetingObj?.webinarOptions?.likes?.showCount ||
        //             this.roomStatus?.localParticipant?.isHost ||
        //             this.roomStatus?.localParticipant?.isCoHost)
        //     )
        // ) {
        //     event.stopImmediatePropagation();
        //     return;
        // }

        return new Promise((resolve, reject) => {
            this.webinarConfService
                .getLikeDetails(this.callStateManagerService.getCurrentMeetingId() || this.webinarId)
                .subscribe(
                    (res: any) => {
                        this.updateReactionsIndividualCount(res);
                        resolve(res);
                    },
                    (err) => {
                        console.log(`Err in getLikeDetails API: `, err);
                        reject(err);
                    }
                );
        });
    }

    async updateReactionsIndividualCount(arr) {
        try {
            if (!this.eventUpdatesObj) await this.getEventUpdates();
        } catch (err) {
            return;
        }
        if (!arr) return;
        this.floatingService.setReactionsDetails(arr);
        let totalReactionCount = 0;
        this.setLikeOptions(arr);
        this.eventStats?.likeOptions.forEach((op) => (totalReactionCount += op.count));
        this.eventStats.likesCount = this.utilService.convertIntegerToShortFormat(totalReactionCount);
    }

    setLikeOptions(arr) {
        this.eventStats.likeOptions = this.eventUpdatesObj?.likes?.reactionType?.map((option: any) => {
            return {
                type: option,
                count: arr.filter((o) => o._id === option)[0] ? arr.filter((o) => o._id === option)[0].count : 0
            };
        });
    }

    getEventStats() {
        return this.eventStats;
    }

    handleNewAttendeeJoin(event) {
        if (event.data.attendeeAction === 'handraise') {
            this.toasterService.clear();
            this.eventEmitterService.emit({ type: 'hand', data: event.data });
            const handRaisedCount = event.data.handRaisedAttendeesCount;
            if (
                !this.callStateManagerService.networkingRoom &&
                (this.roomStatus?.localParticipant?.isHost || this.roomStatus?.localParticipant?.isCoHost)
            ) {
                if (handRaisedCount > 0) {
                    this.toasterService.info(event.data.attendeeName + ' and others raised their hand');
                } else {
                    this.toasterService.info(event.data.attendeeName + ' has raised their hand');
                }
            }
        } else if (event.data.attendeeAction === 'lowerhand') {
            this.eventEmitterService.emit({ type: 'hand', data: event.data });
        }
        if (this.callStateManagerService.meetingObj?.meetingId === event?.data?.meetingId) {
            this.eventEmitterService.emit({
                type: WEBINAR_CONFERENCE_EVENTS.ATTENDEE_PARTICIPATED,
                data: null
            });
        }
    }

    resetNotificationCount() {
        this.notificationCount = {
            chat: 0,
            qa: 0,
            comment: 0,
            liveChat: 0,
            games: 0
        };
        this.sendNotificationCountUpdate();
    }
    getCurrentMeetingId() {
        return this.callStateManagerService.getCurrentMeetingId() || this.webinarId;
    }

    get isStudioEnabled() {
        return (
            this.eventUpdatesObj.customLayout?.customLayoutEnabled &&
            !!this.eventUpdatesObj.customLayout?.lastSavedLayout?.id
        );
    }
}
